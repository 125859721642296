import React from 'react'

import './emailobfuscator.scss'

function Span({ display, text }) {
  return (
    <span display={display}>{text}</span>
  );
}

function Spacer() {
  return (
    <Span text='###' display='none' />
  );
};

const EmailObfuscator = ({ name, domain, tld, size, style }) => {
  tld = tld[0] === '.' ? tld : '.' + tld;

  var emailStyle = Object.assign({
    fontSize: '' + (1 * size) + 'vh',
  }, style);

  return (
    <span className='email-obfuscation' style={emailStyle}>
      <Span text={name} /><Spacer /><Span text='@' /><Spacer /><Span text={domain} /><Spacer /><Span text={tld} /><Spacer />
    </span>
  )
}

export default EmailObfuscator
