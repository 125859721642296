import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import "./shop-card.scss"

const MatchStaticImage = (children) => {
  return children;
}

const ShopCard = ({ enableCarousel, children }) => {
  var carousel = null;
  if (enableCarousel) {
    carousel = (
      <Carousel centerMode={true} centerSlidePercentage="80" dynamicHeight={true} renderThumbs={MatchStaticImage}>
        <StaticImage src="../../static/img/carousel-1.jpg" alt="Jars of honey" />
        <StaticImage src="../../static/img/carousel-3.jpg" alt="10oz honey" />
        {/* Due to a bug in gatsby-plugin-image and/or react-responsive-carousel, using too many large StaticImages
            can cause image loading failures, especially on large-screen devices. We still use StaticImages for the
            first few in the carousel to improve perceived page load times, but the remainder use legacy img tags. */}
        <img src="/img/carousel-4.jpg" alt="24oz honey" />
        <img src="/img/carousel-5.jpg" alt="Honeycomb frame with bees" />
        <img src="/img/carousel-6.jpg" alt="Bee colonies" />
        <img src="/img/carousel-7.jpg" alt="Honey dipper" />
        <img src="/img/carousel-8.jpg" alt="Blueberries" />
        <img src="/img/carousel-9.jpg" alt="California Lilac" />
        <img src="/img/carousel-10.jpg" alt="Strawberries" />
        <img src="/img/carousel-11.jpg" alt="Lantana" />
        <img src="/img/carousel-12.jpg" alt="Wildflowers" />
      </Carousel>
    )
  }
  return (
    <div className="shop-card">
      {carousel}
      <div>
        {children}
      </div>
    </div>
  )
}

ShopCard.defaultProps = {
  enableCarousel: true,
}

export default ShopCard
